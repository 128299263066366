import { Column } from '@components/common/display/Div';
import Banner from '@components/map/Banner';
import BottomInfo from '@components/map/BottomInfo';
import KakaoMap from '@components/map/KakaoMap';

const Map = () => {
  return (
    <div id="map" className="pt-4">
        <Column className="mx-auto w-full px-10 pt-20">
        <Banner title="오시는길"/>
        <KakaoMap />
        <BottomInfo />
        </Column>
    </div>
  );
};

export default Map;
