import React, { CSSProperties, ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';
import { animated, config, useSpring } from 'react-spring';

interface Props {
  children: ReactNode;
  style?: CSSProperties;
  delay?: number;
  threshold?: number;
  animation?: any;
  className?: string;
}
const AnimatedDiv = ({
  children,
  style,
  delay = 0,
  threshold,
  className,
}: Props) => {
  const { inView, ref } = useInView({ triggerOnce: true, threshold });
  const animation = useSpring({
    opacity: inView ? 1 : 0,
    y: inView ? 0 : 50,
    config: { ...config.molasses, duration: 500 },
    delay,
  });
  return (
    <animated.div
      className={`w-full ${className}`}
      ref={ref}
      style={{ ...animation }}
    >
      {children}
    </animated.div>
  );
};

export default AnimatedDiv;
