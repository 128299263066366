import { useEffect, useState } from 'react';
import { useLocation} from 'react-router-dom';
import { MENU_LIST } from 'constants/common';

import { Images } from '@components/common/images';
import {Link} from 'react-scroll'

const Header = () => {
  const location = useLocation();

  const [headerClass, setHeaderClass] = useState<string>();

  useEffect(() => {
    if (location) {
      const className =
        location.pathname === '/'
          ? 'fixed bg-gray-600 bg-opacity-60'
          : 'bg-gray-600';
      setHeaderClass(className);
    }
  }, [location]);

  return (
    <header
      className={`${headerClass} z-10 flex w-screen items-center justify-between px-14 py-7`}
    >
      <a href={'/'}>
        <img src={Images.logo} alt="company logo" className="min-w-full h-10 mr-2 " />
      </a>
      <nav>
        <ul className="flex space-x-4">
          {MENU_LIST.map((item) => (
              <Link to={item.value} smooth={true}>
                <div
                    key={item.label}
                    className="text-gray-200 hover:text-gray-800 cursor-pointer">
                  {item.label}
                </div>
              </Link>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
