import React from "react";
import {PieChart, Pie, Cell, ResponsiveContainer} from "recharts";

const data = [
  { name: "특급기술자", value: 4 },
  { name: "고급기술자", value: 3 },
  { name: "중급기술자", value: 2 },
  { name: "초급기술자", value: 3 }
];

const COLORS = ["#F5EFE7", "#D8C4B6", "#4F709C", "#7267a8"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x>cx ? x-25 : x+25}
      y={y}
      fill="#0D1282"
      textAnchor={x > cx ? "start" : "end"}
      fontWeight={700}
      fontSize={13}
      dominantBaseline="central"
    >
      {`${data[index].name} ${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const OrganizationChart = () => {
  return (
    <PieChart width={350} height={290}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={120}
        fill="#8884d8"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
}

export default OrganizationChart