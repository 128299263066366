import { AiFillMail } from 'react-icons/ai';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FaBus, FaFax, FaSubway } from 'react-icons/fa';
import { IoLocation } from 'react-icons/io5';
import { BUS_LIST } from 'constants/Map';
import { groupByColor } from 'lib/utils';

import AnimatedDiv from '@components/common/animated/AnimatedDiv';
import Badges from '@components/common/display/Badges';
import { Column, Row } from '@components/common/display/Div';
import { TxtSm } from '@components/common/display/Text';

import IconRow from './IconRow';
import InfoItem from './InfoItem';

const BottomInfo = () => {
  const groupedByColor = groupByColor(BUS_LIST);

  return (
    <Column className="gap-4 mt-20">
      <AnimatedDiv className="flex flex-col items-center">
        <Column className="w-full md:w-2/3 gap-2">
          <IconRow title="버스 이용 시" icon={<FaBus size="1.2rem" />} />
          <TxtSm className="text-gray-400">구의역 2번출구</TxtSm>
          {Object.keys(groupedByColor).map((color, index) => (
            <Row key={index} className={'gap-2'}>
              {groupedByColor[color].map((bus, index) => (
                <Badges key={index} className={color} title={bus.label} />
              ))}
            </Row>
          ))}
        </Column>
      </AnimatedDiv>
      <AnimatedDiv className="flex flex-col items-center">
        <hr className="h-px my-2 bg-gray-200 dark:bg-gray-700 w-[calc(100%-30rem)]" />
        <Column className="w-full md:w-2/3 gap-2">
          <IconRow title="지하철 이용 시" icon={<FaSubway size="1.2rem" />} />
          <TxtSm className="text-gray-400">구의역 2호선 2번출구 앞</TxtSm>
          <Badges className={'bg-green-400'} title={'2'} />
        </Column>
      </AnimatedDiv>
      <AnimatedDiv>
        <Column className="bg-slate-100 mt-10 mb-10 p-5 rounded-md gap-3 w-full">
          <Row className="mx-auto w-full">
            <InfoItem
              title="주소"
              content="서울 광진구 아차산로53길 12-7"
              icon={<IoLocation size="1.2rem" />}
            />

            <InfoItem
              title="이메일"
              content="syj001@perform.co.kr"
              icon={<AiFillMail size="1.2rem" />}
            />
          </Row>

          <Row className="mx-auto w-full">
            <InfoItem
              title="팩스"
              content="02-1111-1111"
              icon={<FaFax size="1.2rem" />}
            />
            <InfoItem
              title="연락처"
              content="010-9453-0486"
              icon={<BsFillTelephoneFill size="1.2rem" />}
            />
          </Row>
        </Column>
      </AnimatedDiv>
    </Column>
  );
};

export default BottomInfo;
