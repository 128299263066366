import React, {useCallback, useState} from 'react';
import Button from "@components/common/display/Button";
import Section from "@components/layout/Section";

const Enquiry = () => {

    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const onSubjectChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) =>
        setSubject(e.target.value), [])

    const onMessageChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        // 줄바꿈 적용
        const replaceMessage: string = e.target.value.replaceAll("\n", "%0A")
        setMessage(replaceMessage)}, [])

    return (<Section>
        <div id="enquiry" className="bg-white dark:bg-gray-900 pt-20">
            <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
                    문의하기
                </h2>
                <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
                    문의 내용과 답변받을 Email을 아래 양식에 입력해주세요.
                </p>
                <form action={`mailto:syj001@perform.co.kr?subject=${subject}&&body=${message}`} method="post" encType="text/plain" className="space-y-8">
                    <div>
                        <label htmlFor="subject"
                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">제목</label>
                        <input type="text" id="subject"
                               className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                               onChange={onSubjectChange}
                               placeholder="제목을 입력 해주세요." required/>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="message"
                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                            내용
                        </label>
                        <textarea id="message" rows={10}
                                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                  placeholder="내용을 입력 해주세요."
                                  onChange={onMessageChange}
                        ></textarea>
                    </div>
                    <div className="flex justify-center ">
                        <Button className="bg-blue-950">전송</Button>
                    </div>
                </form>
            </div>
        </div>
    </Section>)
};

export default Enquiry;
