interface MENU_INFO {
  value: string;
  label: string;
}

export const MENU_LIST: MENU_INFO[] = [
  {
    value: 'history',
    label: '연혁',
  },
  {
    value: 'introduction',
    label: '소개',
  },
  {
    value: 'enquiry',
    label: '문의하기',
  },
  {
    value: 'map',
    label: '찾아오시는 길',
  },
];
