import {ORGANIZATION} from "../../constants/organization";
import React from "react"
import OrganizationChart from "@components/introduction/OrganizationChart";
import Section from "@components/layout/Section";
import {ResponsiveContainer} from "recharts";

const Organization = () => {

    return (
        <Section className="mx-[7vw]">
            <h3 className="text-xl mb-[10px] font-bold tracking-tight text-gray-900">
                조직구성
            </h3>
            <h6 className="text-m tracking-tight text-gray-900">
                효과적인 프로젝트를 수행하기 위한 인력 구성으로 조직을 구성 / 투입인력은 각 분야 경험이 풍부한 전문가로 구성하여 진행합니다.
            </h6>
            <div className="grid grid-cols-1 lg:grid-cols-2">
                <div
                    className="mr-10 mt-10 grid mb-8 border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 md:mb-12 lg:grid-cols-2">
                    {ORGANIZATION.map((position, index) => (
                        <figure key={position.role}
                                className={index === 0 ? "flex flex-col items-center justify-center p-8 bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-tl-lg md:border-r dark:bg-gray-800 dark:border-gray-700"
                                    : index === 1 ? "flex flex-col items-center justify-center p-8 bg-white border-b border-gray-200 rounded-tr-lg dark:bg-gray-800 dark:border-gray-700"
                                        : index === 2 ? "flex flex-col items-center justify-center p-8 bg-white border-b border-gray-200 rounded-bl-lg md:border-b-0 md:border-r dark:bg-gray-800 dark:border-gray-700"
                                            : "flex flex-col items-center justify-center p-8  bg-white border-gray-200 rounded-b-lg md:rounded-br-lg dark:bg-gray-800 dark:border-gray-700"}
                        >
                            <blockquote className="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
                                <h3 className="mb-8 text-lg font-semibold text-gray-900 dark:text-white">{position.role}({position.member}명)</h3>
                                {position.work.map(job => <p key={job} className="mt-1">- {job}</p>)}
                            </blockquote>
                        </figure>
                    ))}
                </div>
                <div className="justify-center items-center inline-block col-span-1">
                    <h6 className="font-bold tracking-tight text-gray-900 text-center">기술인력(12명)</h6>
                    <div className="flex items-center justify-center">
                    <OrganizationChart/>
                    </div>
                    <div className="mt-9">
                        <div className="mx-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 sm:px-6 lg:px-8">
                                <div className="rounded-lg shadow">
                                    <table className="min-w-full text-center text-sm font-light">
                                        <thead
                                            className="border-x font-normal text-white bg-gray-800">
                                        <tr>
                                            <th scope="col" className="border-r px-6 py-3">구분</th>
                                            <th scope="col" className="border-r px-3 py-3">계</th>
                                            <th scope="col" className="border-r px-6 py-3">컨설팅</th>
                                            <th scope="col" className="border-r px-6 py-3">서비스</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr className="border-x dark:border-neutral-500">
                                            <td className="whitespace-nowrap border-r px-6 py-3 font-medium">계</td>
                                            <td className="whitespace-nowrap border-r px-3 py-3">12</td>
                                            <td className="whitespace-nowrap border-r px-6 py-3">2</td>
                                            <td className="whitespace-nowrap border-r px-6 py-3">10</td>
                                        </tr>
                                        <tr className="border-x dark:border-neutral-500">
                                            <td className="whitespace-nowrap border-r px-6 py-3 font-medium">특급기술자</td>
                                            <td className="whitespace-nowrap border-r px-3 py-3">4</td>
                                            <td className="whitespace-nowrap border-r px-6 py-3">2</td>
                                            <td className="whitespace-nowrap border-r px-6 py-3">2</td>
                                        </tr>
                                        <tr className="border-x dark:border-neutral-500">
                                            <td className="whitespace-nowrap border-r px-6 py-3 font-medium">고급기술자</td>
                                            <td className="whitespace-nowrap border-r px-3 py-3">3</td>
                                            <td className="whitespace-nowrap border-r px-6 py-3">0</td>
                                            <td className="whitespace-nowrap border-r px-6 py-3">3</td>
                                        </tr>
                                        <tr className="border-x dark:border-neutral-500">
                                            <td className="whitespace-nowrap border-r px-6 py-3 font-medium">중급기술자</td>
                                            <td className="whitespace-nowrap border-r px-3 py-3">2</td>
                                            <td className="whitespace-nowrap border-r px-6 py-3">0</td>
                                            <td className="whitespace-nowrap border-r px-6 py-3">2</td>
                                        </tr>
                                        <tr className="border-x border-b dark:border-neutral-500">
                                            <td className="whitespace-nowrap border-r px-6 py-3 font-medium">초급기술자</td>
                                            <td className="whitespace-nowrap border-r px-3 py-3">3</td>
                                            <td className="whitespace-nowrap border-r px-6 py-3">0</td>
                                            <td className="whitespace-nowrap border-r px-6 py-3">3</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default Organization