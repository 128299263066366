interface POSITION {
    role: string
    member: number
    work: string[]
}

export const ORGANIZATION: POSITION[] = [
    {
        role: "Project Management Office",
        member: 2,
        work: [
            "운영업무 프로세스 재설계",
            "운영업무 프로세스 정립",
            "프로젝트 산출물 관리",
            "프로젝트 전반의 자원관리",
            "프로젝트 전반의 일정관리",
            "이슈사항 정리",
            "프론트 및 백엔드 개발",
        ]
    },
    {
        role: "Project Manager",
        member: 2,
        work: [
            "운영업무 프로세스 재설계",
            "운영업무 프로세스 정립",
            "이슈사항 정리",
            "데이터베이스 설계",
            "어플리케이션 설계",
            "인터페이스 설계",
        ]
    },
    {
        role: "Project Leader",
        member: 3,
        work: [
            "플랫폼 총괄책임",
            "설계 및 구현단계책임",
            "프론트 및 백엔드 개발",
        ]
    },
    {
        role: "Project Engineer",
        member: 5,
        work: [
            "프론트 및 백엔드 개발",
            "모바일 개발",
        ]
    }
];
