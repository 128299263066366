import { ReactNode } from 'react';

import { Column, Row } from '@components/common/display/Div';
import { TxtLg, TxtSm } from '@components/common/display/Text';

import IconRow from './IconRow';

interface InfoItemProps {
  title: string;
  content: string;
  icon: ReactNode;
}

const InfoItem = ({ title, content, icon }: InfoItemProps) => {
  return (
    <Column className="w-full md:w-2/3 gap-1">
      <IconRow icon={icon} title={title} />
      <TxtSm>{content}</TxtSm>
    </Column>
  );
};

export default InfoItem;
