import Section from "@components/layout/Section";
import {Images} from "@components/common/images";
import React from "react";

const ProgramBuild = () => {
    return <Section>
        <h3 className="mx-[10vw] text-xl mb-[10px] font-bold tracking-tight text-gray-900">
            프로그램 구축방안
        </h3>
        <h6 className="mx-[10vw] text-m tracking-tight text-gray-900">
            가상의 대용량 연동 테스트를 통해 시스템 기능과 성능 체크 와 적합성/안정성 검토하며,
            Open 전 통합 테스트를 통해 실제 부하데이터 이상을 감안하여 테스트를 수행하여 통합성과 안정성을 보장합니다.
        </h6>

        <img
            src={Images.testProcess}
            alt="Images"
            className="pl-[10vw] pr-[10vw] mt-10 h-full w-full object-cover object-center lg:h-full lg:w-full"/>
    </Section>
}

export default ProgramBuild