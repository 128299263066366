export const groupByColor = (list: { label: string; color: string }[]) => {
  return list.reduce<{ [key: string]: { label: string; color: string }[] }>(
    (groups, bus) => {
      if (!groups[bus.color]) {
        groups[bus.color] = [];
      }
      groups[bus.color].push(bus);
      return groups;
    },
    {},
  );
};
