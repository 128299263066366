import Section from "@components/layout/Section";
import React from "react";
import {QUALITY_PLAN_LIST} from "../../constants/qualityPlan";

const QualityPlan = () => {
    return <Section>
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-7xl">
                    <div className="text-center">
                    <h2 className="text-base font-semibold leading-7 text-indigo-600">최고의 품질제공을 통한 고객만족과 프로젝트 성공 실현</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">고객만족 품질관리활동</p>
                    <p className="mt-6 text-sm leading-8 text-gray-600">고객에 최적화 된 방법론 적용 / 고객 요구사항 만족 / 제품 품질수준 만족</p>
                    </div>
                    <p className="mt-6 text-lg leading-8 text-gray-600">정보기술(IT)의 전 사업분야에 걸쳐 품질경영시스템을 통한 정량적 프로젝트 관리를 추진하고
                        품질 향상을 위하여 지속적인 프로세스 개선활동을 실천하며,정보기술의 광범위한 솔루션을 높은 품질수준으로 고객에게 제공하여 시스템의 안정성과 신뢰성을
                        극대화 함으로써 고객만족 실현합니다.
                    </p>

                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {QUALITY_PLAN_LIST.map((plan, index) => (
                            <div key={plan.name} className="relative pl-16 ml-12">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div
                                        className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-gray-200">
                                        {index === 0 ?
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"/>
                                            </svg>
                                            : index === 1 ?
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"/>
                                                </svg>
                                                : index === 2 ? <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                     viewBox="0 0 24 24" strokeWidth={1.5}
                                                                     stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5"/>
                                                    </svg>
                                                    : <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                           viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                                           className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"/>
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                                    </svg>}

                                    </div>
                                    {plan.name}
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">
                                    {plan.detail.map(content => <div key={content}>{content}<br/></div>)}
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    </Section>
}

export default QualityPlan