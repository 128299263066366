import Section from "@components/layout/Section";
import React from "react";
import {PERFORMANCE} from "../../constants/performanceList";

const Performance = () => {
    return <Section>
        <h3 className="mx-[12vw] mb-[5px] text-xl font-bold tracking-tight text-gray-900">
            주요실적
        </h3>
        <h6 className="mx-[12vw] mb-[30px] text-m tracking-tight text-gray-900">
            ㈜퍼폼시스템은 2021년에 설립한 IT서비스 전문업체로 다양한 분야의 IT 솔루션 구축을 진행하고 있으며
            <br/>WMS/WCS/수출업무 솔루션 구축등을 주요 사업영역으로 하고 있습니다.
        </h6>

        <div className="container max-w-[70vw] px-4 mx-auto sm:px-8">
            <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
                <div className="inline-block min-w-full overflow-hidden rounded-lg shadow">
                    <table className="min-w-full leading-normal">
                        <thead>
                        <tr>
                            <th scope="col"
                                className="px-7 py-4 text-sm font-normal text-white bg-gray-800 text-left uppercase border-b border-gray-200">
                                고객사
                            </th>
                            <th scope="col"
                                className="px-5 py-4 text-sm font-normal text-white bg-gray-800 text-left uppercase border-b border-gray-200">
                                프로젝트
                            </th>
                            <th scope="col"
                                className="px-5 py-4 text-sm font-normal text-white bg-gray-800 text-left uppercase border-b border-gray-200">
                                수주
                            </th>
                            <th scope="col"
                                className="px-5 py-4 text-sm font-normal text-white bg-gray-800 text-left uppercase border-b border-gray-200">
                                진행현황
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {PERFORMANCE.map ( project => (
                            <tr key={project.customer+project.name}>
                                <td className="px-5 py-4 text-sm bg-white border-b border-gray-200">
                                    <div className="flex items-center">
                                        <div className="ml-3">
                                            <p className="text-gray-900 whitespace-no-wrap">{project.customer}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className="px-5 py-4 text-sm bg-white border-b border-gray-200">
                                    <p className="text-gray-900 whitespace-no-wrap">{project.name}</p>
                                </td>
                                <td className="px-5 py-4 text-sm bg-white border-b border-gray-200">
                                    <p className="text-gray-900 whitespace-no-wrap">{project.date}</p>
                                </td>
                                <td className="px-5 py-4 text-sm bg-white border-b border-gray-200">
                                    <span
                                        className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                                        <span aria-hidden="true"
                                              className={`absolute inset-0 ${project.progress === "완료" ? "bg-green-200" : "bg-indigo-200"} rounded-full opacity-50`}>
                                        </span>
                                        <span className="relative">{project.progress}</span>
                                    </span>
                                </td>
                            </tr>
                        ))}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </Section>
}

export default Performance