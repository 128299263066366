import React, {useCallback, useRef} from 'react';

import AnimatedDiv from '@components/common/animated/AnimatedDiv';
import { Column } from '@components/common/display/Div';
import { Images } from '@components/common/images';
import History from "@pages/History";
import Introduction from "@pages/Introduction";
import Enquiry from "@pages/Enquiry";
import Map from "@pages/Map";

const Home = () => {
  return (
      <div id="home">
        <Column className="relative w-full h-[55rem]">
          {/* <div className="absolute min-h-full min-w-full">
            <video className="fixed" autoPlay loop muted src={Images.bg}></video>
          </div> */}
          <video
            className="w-full h-full object-cover absolute"
            autoPlay
            muted
            loop
          >
            <source src={Images.bg} type="video/mp4" />
          </video>
          {/* <video className="fixed" loop autoPlay muted src={Images.bg} /> */}
          <AnimatedDiv delay={250} className="z-0">
            <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-72">
              <div className="text-center">
                <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                  풍부한 경험의 결과
                  <br />
                  퍼폼시스템 <span>WMS 솔루션</span>
                </h1>
                <p className="mt-6 text-l leading-8 text-white">
                  우리는 생각합니다. 물류사 입장에서 어떻게 보다 효율적인 시스템을
                  구축을 할 수 있을지.
                  <br />
                  우리는 만듭니다. 특허권을 보유한 솔루션으로 트랜드를 따라 고도화된
                  WMS와
                  <br />
                  물류센터의 유형과 목적에 따라 필요한 기능을 녹여 낸 WMS.
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <a
                    href="#"
                    className="rounded-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    About perform WMS
                  </a>
                  <a
                    href="#"
                    className="text-sm font-semibold leading-6 text-gray-900 hover:text-blue-50"
                  >
                    Learn more <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="relative">
              {/*<a href="https://www.vecteezy.com/video/20823878-creative-design-of-3d-background-with-neon-colors-and-liquid-gradients-neon-colors-vibrant-gradients-3d -animation-seamless-loop-in-4k-abstract-colorful-wave-backdrop-seamless-loop">Vecteezy의 스톡 비디오</a>*/}
            </div>
          </AnimatedDiv>
        </Column>
    </div>
  );
};

export default Home;
