import { ReactNode } from 'react';

interface TextProps {
  className?: string;
  children?: ReactNode;
}

export const TxtSm = ({ className, children }: TextProps) => (
  <p className={`text-sm ${className}`}>{children}</p>
);
export const TxtBase = ({ className, children }: TextProps) => (
  <p className={`text-base ${className}`}>{children}</p>
);
export const TxtLg = ({ className, children }: TextProps) => (
  <p className={`text-lg ${className}`}>{children}</p>
);
export const TxtXl = ({ className, children }: TextProps) => (
  <p className={`text-xl ${className}`}>{children}</p>
);
export const Txt2xl = ({ className, children }: TextProps) => (
  <p className={`text-2xl ${className}`}>{children}</p>
);
