import React from 'react';
import Certificate from "@components/introduction/Certificate";
import Organization from "@components/introduction/Organization";
import Architecture from "@components/introduction/Architecture";
import ProgramBuild from "@components/introduction/ProgramBuild";
import Performance from "@components/introduction/Performance";
import QualityPlan from "@components/introduction/QualityPlan";
import TestPlan from "@components/introduction/TestPlan";
import Banner from "@components/map/Banner";
import {Column} from "@components/common/display/Div";
const Introduction = () => {
  return <div id="introduction" className="py-10">
    <Certificate/>
    <Organization/>
    <Performance/>
    <Architecture/>
    <ProgramBuild/>
    <TestPlan/>
    <QualityPlan/>
  </div>;
};

export default Introduction;
