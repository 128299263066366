import {ReactNode} from "react";

interface DivProps {
  className?: string;
  children?: ReactNode;
}

const Section = ({className, children}:DivProps) => {
    return   <div className={`bg-white border-b-2 py-[5vw] ${className}`}>{children}</div>
}

export default Section