import React from 'react';

import { TxtSm } from './Text';

interface BadgesProps {
  className?: string;
  title: string;
}

const Badges = ({ className, title }: BadgesProps) => {
  return (
    <TxtSm
      className={`text-white text-xs font-medium  px-2.5 py-0.5 rounded-full w-fit ${className}`}
    >
      {title}
    </TxtSm>
  );
};

export default Badges;
