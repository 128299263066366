import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="relative bg-gray-800 w-screen">
      <div className="max-w-6xl mx-auto py-4 px-10 sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
          <div className="px-5 py-2">
            <a
              href="#"
              className="text-gray-600 hover:text-gray-300 font-semibold"
            >
              Perform System
            </a>
          </div>
          <div className="px-5 py-2">
            <a
              href="#"
              className="text-gray-600 hover:text-gray-300 font-semibold"
            >
              {/*문의 전화 00000-00000*/}
            </a>
          </div>
          <div className="px-5 py-2">
            <a
              href="#"
              className="text-gray-600 hover:text-gray-300 font-semibold"
            >
              서울 광진구 아차산로53길 12-7 2층
            </a>
          </div>
        </nav>
        <div className="mt-8">
          <p className="text-center text-gray-600">
            Copyright&copy; 2023 Perform System All right reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
