import { ReactNode } from 'react';

interface ButtonProps {
  className?: string;
  children?: ReactNode;
}

const Button = ({className, children = "입력"}:ButtonProps) => {
    return <button className={`${className} bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg`}>
        {children}
    </button>
}

export default Button