import {Images} from "@components/common/images";
interface CERTIFICATE {
  label: string;
  image: string;
}

export const CERTIFICATE_LIST: CERTIFICATE[] = [
  {
    label: "저작권등록증",
    image: Images.certificate.copyright
  },
  {
    label: "출원사실증명원",
    image: Images.certificate.patent
  },
  {
    label: "벤쳐기업 확인서",
    image: Images.certificate.venture
  },
];
