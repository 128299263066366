import { useCallback, useState } from 'react';
import {
  CustomOverlayMap,
  Map as KaKaoMap,
  MapMarker,
  MapTypeControl,
  ZoomControl,
} from 'react-kakao-maps-sdk';

import AnimatedDiv from '@components/common/animated/AnimatedDiv';
import { Column } from '@components/common/display/Div';
import { TxtSm } from '@components/common/display/Text';

const defaultCenter = { lat: 37.5378, lng: 127.0862 };

const KakaoMap = () => {
  const [isMapLoaded, setMapLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const handleMapLoad = useCallback(() => setMapLoaded(true), []);
  const handleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  return (
    <AnimatedDiv>
      <KaKaoMap
        className="w-full h-[30rem]"
        center={defaultCenter}
        onCreate={handleMapLoad}
        level={2}
      >
        {isMapLoaded && (
          <>
            <MapTypeControl />
            <ZoomControl />
            <MapMarker position={defaultCenter} onClick={handleOpen} />
            {isOpen && (
              <CustomOverlayMap position={defaultCenter} yAnchor={1.5}>
                <Column className="p-2 w-fit border bg-white rounded-md border-black items-center">
                  <TxtSm className="font-bold">
                    서울 광진구 아차산로53길 12-7
                  </TxtSm>
                  <a
                    href={`https://map.kakao.com/link/roadview/${defaultCenter.lat},${defaultCenter.lng}`}
                    target="new"
                    style={{ display: 'grid', placeItems: 'center' }}
                  >
                    <TxtSm className="px-1 py-0.5 bg-sky-400 rounded-md text-white">
                      로드뷰
                    </TxtSm>
                  </a>
                  <hr className="h-px my-2 bg-gray-200 dark:bg-gray-700 w-[calc(100%-2rem)]" />
                  <button onClick={handleOpen}>
                    <TxtSm className="font-bold">닫기</TxtSm>
                  </button>
                </Column>
              </CustomOverlayMap>
            )}
          </>
        )}
      </KaKaoMap>
    </AnimatedDiv>
  );
};

export default KakaoMap;
