import React from 'react';

import Section from "@components/layout/Section";

import {CERTIFICATE_LIST} from "../../constants/certificate";
const Certificate = () => {
    return <Section>
        <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <h3 className="text-xl mb-[10px] font-bold tracking-tight text-gray-900">
                인증서
            </h3>
            <h6 className="text-m tracking-tight text-gray-900">
                ㈜퍼폼시스템은 자체 솔루션 개발을 통하여 다양한 IT프로그램을 구현 가능합니다.
            </h6>

            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
                {CERTIFICATE_LIST.map(certificate => (
                    <div className="group relative" key={certificate.image}>
                        <div
                            className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-140">
                            <img
                                src={certificate.image}
                                alt="Images"
                                className="h-full w-full object-cover object-center lg:h-full lg:w-full"/>
                        </div>
                        <div className="mt-4 text-center">
                            <div>
                                <p className="mt-1 text-sm text-center text-gray-500">{certificate.label}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </Section>
}

export default Certificate