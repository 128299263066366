interface HISTORY_MONTH_INFO {
    month: string,
    content: string
}

interface HISTORY_INFO {
    year: string,
    monthInfo: HISTORY_MONTH_INFO[]
}

export const HISTORY: HISTORY_INFO[] = [
    {
        year: "2021",
        monthInfo: [
            {
                month: "08",
                content: "㈜퍼폼시스템 법인 설립"
            },
            {
                month: "08",
                content: "파일럿 프로젝트(WMS)개발"
            },
            {
                month: "10",
                content: "재고관리 솔루션 개발"
            },
        ]
    },
    {
        year: "2022",
        monthInfo: [
            {
                month: "01",
                content: "㈜이트렁크 협력업체 등록"
            },
            {
                month: "01",
                content: "건설안전관리 솔루션 개발"
            },
            {
                month: "05",
                content: "사내 프레임워크 기능 강화"
            },
            {
                month: "08",
                content: "피폼프레임워크(P-FORM Framework) 저작권 등록"
            },
            {
                month: "10",
                content: "기업 부설연구소 설립(한국산업기술진흥협회)"
            },
            {
                month: "10",
                content: "프레임워크를 이용한 WMS 특허출원"
            },
            {
                month: "11",
                content: "포장박스 자동추천 솔루션 개발"
            },
            {
                month: "11",
                content: "한국소프트웨어산업협회 회원가입"
            },
        ]
    },
    {
        year: "2023",
        monthInfo: [
            {
                month: "02",
                content: "벤처기업인증(기술보증기금)"
            },
            {
                month: "03",
                content: "WCS 솔루션 개발"
            },
        ]
    }
]
