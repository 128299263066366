import React from 'react';
import {HISTORY} from "../constants/history";

const History = () => {
    return(
        <div id="history" className="bg-white border-solid">
            <div
                className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-1 lg:px-8">
                <div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Perform System</h2>
                    <p className="mt-4 text-gray-500">2021년에 설립한 IT서비스 전문업체로 다양한 분야의 IT 솔루션 구축을 진행하고 있으며 WMS/WCS/수출업무 솔루션 구축 등을 주요 사업영역으로 하고 있습니다.<br/>
                    전체 인원중 95% 이상이 IT전문 인력으로 구성되어 있으며 그중 40%의 인력이 기술연구소에 배치되어 사내 프래임워크 기능강화에 힘을 쏟고 있습니다.<br/>
                    저희 ㈜퍼폼시스템은 고객의 소리에 한걸음 먼저 대응하고, 책임감 있는 서비스로 고객 비즈니스 가치를 최고로 만드는데 기여하겠습니다.
                    </p>

                    <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-1 sm:gap-y-4 lg:gap-x-8">
                        {HISTORY.map(history => (
                            <div className="border-t border-gray-200 pt-4" key={history.year}>
                                <dt className="font-medium text-gray-900">{history.year}년</dt>
                                {history.monthInfo.map(info => (
                                    <dd className="mt-2 text-sm text-gray-500">{`${history.year}.${info.month} - ${info.content}` }</dd>
                                ))}
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
};

export default History;
