interface PLAN {
    name: string
    detail: string[]
}

export const QUALITY_PLAN_LIST: PLAN[] = [
    {
        name: "품질수준",
        detail: [
            "분석단계에서 품질수준 정의"
        ]
    },
    {
        name: "기본체계",
        detail: [
            "품질매뉴얼",
            "품질규정/표준 프로세스/방법론",
            "절차서/지침서"
        ]
    },
    {
        name: "품질보증",
        detail: [
            "산출물 품질 검토",
            "프로세스 이행 검토",
            "내부 품질검토/내부 감리 실시"
        ]
    },
    {
        name: "품질통제",
        detail: [
            "Inspection",
            "품질테스트",
            "측정 및 분석"
        ]
    },
];
