import React, { ReactNode } from 'react';

import { Row } from '@components/common/display/Div';
import { TxtLg } from '@components/common/display/Text';

interface IconRowProps {
  title: string;
  icon: ReactNode;
}

const IconRow = ({ title, icon }: IconRowProps) => {
  return (
    <Row className="items-center gap-1">
      {icon}
      <TxtLg className="font-bold">{title}</TxtLg>
    </Row>
  );
};

export default IconRow;
