import React from "react";

import {Images} from "@components/common/images";
import Section from "@components/layout/Section";

const Architecture = () => {
    return <Section>
        <h3 className="mx-[10vw] text-xl mb-[10px] font-bold tracking-tight text-gray-900">
            기술 및 기능
        </h3>
        <h6 className="mx-[10vw] text-m tracking-tight text-gray-900">
            ㈜퍼폼시스템 WMS 시스템은 Vue.js, Java Spring Boot, iBatis 기반으로 구성된
            Cross Operating System / Cross Platform을 지원하는 최신 웹 개발 시스템 아키텍처로 구축합니다.
        </h6>
        <img
            src={Images.architecture}
            alt="Images"
            className="pl-[10vw] pr-[10vw] mt-10 h-full w-full object-cover object-center lg:h-full lg:w-full"/>
    </Section>
}

export default Architecture