import React, {useCallback} from 'react';
import {BrowserRouter} from 'react-router-dom';

import Footer from '@components/layout/Footer';
import Header from '@components/layout/Header';
import Home from '@pages/Home';
import History from "@pages/History";
import Introduction from "@pages/Introduction";
import Enquiry from "@pages/Enquiry";
import Map from "@pages/Map";

const AppRouter = () => {


  return (
    <BrowserRouter basename="/">
      <Header />
      <main className="bg-white">
          <Home/>
          <History/>
          <Introduction/>
          <Enquiry/>
          <Map/>
      </main>
      <Footer />
    </BrowserRouter>
  )
};

export default AppRouter;
