export const Images = {
  //프로젝트 로고
  logo: require('@assets/logo.png'),
  banner: require('@assets/banner.jpg'),
  //wms 프로그램
  // wms: require('./wms_img.png'),
  //home 배경 영상
  bg: require('@assets/background.mp4'),
  certificate:{
    copyright: require('@assets/certificate-copyright.jpg'),
    patent: require('@assets/certificate-patent.jpg'),
    venture: require('@assets/certificate-venture.jpg'),
  },
  architecture: require('@assets/architecture.png'),
  testProcess: require('@assets/test-process.png'),
};
