import React from 'react';

import { Column } from '@components/common/display/Div';
import { Txt2xl } from '@components/common/display/Text';

interface BannerProps{
    title?: string
}
const Banner = ({title}:BannerProps) => {
  return (
    <Column className="w-[calc(100%+5rem)] h-20 bg-gray-800 p-5 mb-10 -ml-[2.5rem]">
      <Txt2xl className="font-bold mb-2 text-white">{title}</Txt2xl>
      <hr className="bg-gray-200 w-[10rem] border-2 rounded" />
    </Column>
  );
};

export default Banner;
