import React from "react";

import Section from "@components/layout/Section";

const TestPlan = () => {
    return <Section>
        <h3 className="mx-[10vw] text-xl mb-[10px] font-bold tracking-tight text-gray-900">
            시스템 시험방안
        </h3>
        <h6 className="mx-[10vw] mb-10 text-m tracking-tight text-gray-900">
            테스트는 안정적인 시스템 오픈을 위해 테스트 대상 센터에 대해 실제 운영을 수행하여 시스템/데이터 차원의 안정성과 정합성을 조기에 확보하기 위해 수행합니다.
        </h6>
        <div className="mx-[10vw] py-10 grid grid-cols-1 lg:grid-cols-2 border-2 rounded-lg">
            <div className="text-xl">
                <div className="text-xl font-bold mb-2 text-center mt-5">시스템 테스트 추진 전략</div>
                <div className="text-sm font-bold text-indigo-600 text-center mb-5">안정적인 시스템 오픈</div>
                <div
                    className="h-[190px] py-3 flex flex-col items-center bg-white border-t border-r border-gray-200 shadow md:flex-row md:max-w-xldark:border-gray-700 dark:bg-gray-800">
                    <div
                        className="text-indigo-800 w-full rounded-t-lg md:h-auto md:w-1/3 md:rounded-none md:rounded-l-lg text-base font-bold px-4">
                        테스트(Field Test)를 통한 안정성 확보
                    </div>
                    <div className="flex flex-col justify-between p-4 leading-normal">
                        <h5 className="mb-2 text-sm tracking-tight text-gray-900 dark:text-white">
                            테스트 대상 현장에서 Field Test 수행
                        </h5>
                        <h5 className="mb-2 text-sm tracking-tight text-gray-900 dark:text-white">
                            시범 운영을 통한 사전에 장애 해결 및 안정성 조기 확보
                        </h5>
                    </div>
                </div>
                <div
                    className="py-3 flex flex-col items-center bg-white border-gray-200 rounded-lg shadow md:flex-row md:max-w-xldark:border-gray-700 dark:bg-gray-800">
                    <div
                        className="text-indigo-800 w-full rounded-t-lg md:h-auto md:w-1/3 md:rounded-none md:rounded-l-lg text-sm font-bold px-4">
                        테스트 기간 동안 통합 시스템 내 데이터 정합성 확보
                    </div>
                    <div className="flex flex-col justify-between p-4 leading-normal">
                        <h5 className="mb-2 text-sm tracking-tight text-gray-900 dark:text-white">
                            테스트 기간 동안 기존 시스템의 마스터 데이터 동기화 수행
                        </h5>
                    </div>
                </div>
            </div>
            <div className="text-xl border-r">
                <div className="text-center font-bold py-9">테스트 대상</div>
                <div
                    className="py-5 flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xldark:border-gray-700 dark:bg-gray-800">
                    <div
                        className="text-indigo-800 text-center text-base w-full rounded-t-lg md:h-auto md:w-1/3 md:rounded-none md:rounded-l-lg font-bold px-4">
                        테스트 대상
                    </div>
                    <div className=" flex flex-col justify-between p-4 leading-normal">
                        <h5 className="mb-2 text-base font-bold tracking-tight text-gray-900 dark:text-white">
                            테스트를 위한 대상 시스템
                        </h5>
                        <h5 className="mb-2 text-sm tracking-tight text-gray-900 dark:text-white">
                            고객사 안전관리시스템
                        </h5>
                    </div>
                    <div className=" flex flex-col justify-between p-4 leading-normal">
                        <h5 className="mb-2 text-base font-bold tracking-tight text-gray-900 dark:text-white">
                            테스트 대상 센터 선정기준
                        </h5>
                        <h5 className="mb-2 text-sm tracking-tight text-gray-900 dark:text-white">
                            1차 오픈 선정 현장 및 협력사
                        </h5>
                    </div>
                </div>
                <div
                    className="py-5 flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xldark:border-gray-700 dark:bg-gray-800">
                    <div
                        className="text-indigo-800 text-center text-base w-full rounded-t-lg md:h-auto md:w-1/3 md:rounded-none md:rounded-l-lg font-bold px-4">
                        테스트 후 오픈 방식
                    </div>
                    <div className=" flex flex-col justify-between p-4 leading-normal">
                        <h5 className="mb-2 text-base font-bold tracking-tight text-gray-900 dark:text-white">
                            테스트(안정성 / 정합성 확보)
                        </h5>
                        <h5 className="mb-2 text-sm tracking-tight text-gray-900 dark:text-white">
                            테스트 센터(통합 시스템) &#8594;
                        </h5>
                    </div>
                    <div className=" flex flex-col justify-between p-4 leading-normal">
                        <h5 className="mb-2 text-base font-bold tracking-tight text-gray-900 dark:text-white">
                            전 현장 / 협력사 확산
                        </h5>
                        <h5 className="mb-2 text-sm tracking-tight text-gray-900 dark:text-white">
                            전 현장/협력사 시스템 오픈
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </Section>
}

export default TestPlan