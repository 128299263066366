interface PROJECT {
    date: string
    customer: string
    name: string
    progress: string
}

export const PERFORMANCE: PROJECT[] = [
    {
        date: "2021.10",
        customer: "(주)대동금속",
        name: "스마트물류 구축",
        progress: "완료",
    },
    {
        date: "2021.11",
        customer: "㈜씨존해운",
        name: "㈜홈페이지 구축",
        progress: "완료",
    },
    {
        date: "2021.11",
        customer: "㈜SK매직",
        name: "물류컨설팅",
        progress: "완료",
    },
    {
        date: "2022.01",
        customer: "건양대학교",
        name: "이미지 왜곡보정 프로그램 / 이미지 분할 프로그램 개발",
        progress: "완료",
    },
    {
        date: "2022.01",
        customer: "찬 갤러리",
        name: "쇼핑몰 시스템 개발",
        progress: "완료",
    },
    {
        date: "2022.01",
        customer: "㈜피코이노베이션",
        name: "WMS 구축",
        progress: "완료",
    },
    {
        date: "2022.09",
        customer: "㈜동원엔터프라이즈",
        name: " 한화큐셀음성센터 WMS 프로젝트",
        progress: "완료",
    },
    {
        date: "2022.09",
        customer: "㈜웅진북센",
        name: "웅진북센 WCS/제어시스템",
        progress: "완료",
    },
    {
        date: "2023.02",
        customer: "㈜핏펫",
        name: "스마트물류 구축",
        progress: "진행",
    },
    {
        date: "2023.02",
        customer: "㈜동원산업",
        name: "산업 수출업무시스템 구축(진행중 2023.08 완료)",
        progress: "진행",
    },
    {
        date: "2023.02",
        customer: "㈜비엔아이시스템즈",
        name: "통합구매/물류시스템 고도화 작업 분석/설계(진행중 2023.08 완료)",
        progress: "진행",
    },
    {
        date: "2023.02",
        customer: "(주)하나로TNS",
        name: "물류컨설팅(진행중 2023.12 완료)",
        progress: "진행",
    },
];
