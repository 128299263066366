import { ReactNode } from 'react';

interface DivProps {
  className?: string;
  children?: ReactNode;
}

export const Column = ({ className, children }: DivProps) => (
  <div className={`flex flex-col ${className}`}>{children}</div>
);
export const Row = ({ className, children }: DivProps) => (
  <div className={`flex ${className}`}>{children}</div>
);
