import React from 'react';
import AppRouter from 'router';

import Layout from '@components/layout';
const App = () => {
  return (
    // <Layout>
    <AppRouter />
    // </Layout>
  );
};

export default App;
